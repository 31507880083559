
import { Component, Prop, Vue } from 'vue-property-decorator';
import DoughnutChart from '@/lib/charts/doughnutChart';
import axiosClient from '@/lib/rest/axiosClient';
import { getConfigEnv } from '@/utils/helpers';

@Component({
  components: {
    DoughnutChart,
  },
})
export default class GptOverviewCluster extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  chartOptions = {
    responsive: true,
    borderRadius: 50,
    maintainAspectRatio: false,
    events: [],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 90,
    tooltips: {
      bodyFontSize: 7,
    },
  };

  commonIssues = [];

  chartDataUpdated = 0;

  loadingCommonIssues = false;

  highestPercentageTitle = 'No data to analyze';

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [
      {
        name: 'Tasqs',
        labels: 'Tasqs',
        backgroundColor: ['#273e55', '#FFFFFF'],
        hoverBackgroundColor: ['#273e55', '#FFFFFF'],
        borderColor: ['#273e55', '#FFFFFF'],
        hoverBorderColor: '#FFFFFF',
        hoverBorderWidth: 1,
        borderAlign: 'outer',
        data: [13, 0],
        borderWidth: 2,
        borderRadius: 50,
      },
    ],
  };

  get operatorList() {
    return [
      { operatorID: 8, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 7, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 6, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  async mounted() {
    this.loadingCommonIssues = true;
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const { data: existingRecord }: any = await axiosClient.get(
      '/analysis/well-common-issues',
      {
        params: {
          node_name: this.tasq.wellName,
          operator: operatorDetails['operatorName'],
        },
      }
    );
    const {
      data: { most_common: mostCommon },
    }: any = await axiosClient.get('/analysis/well-common-issues', {
      params: {
        node_name: this.tasq.wellName,
        operator: operatorDetails['operatorName'],
      },
    });


    const filteredData = mostCommon.filter(issue => issue['sub category'] )

    // Calculate the total count
    const totalCount = filteredData.reduce(
      (total, item) => total + item.count,
      0
    );

    // Calculate percentage and return modified filteredData
    let highestPercentage = 0;
    let highestPercentageTitle = '';

    // Calculate percentage, round to nearest whole number, and add "%" symbol
    const modifiedData = filteredData.map((item, index) => {
      const percentage = Math.round((item.count / totalCount) * 100);
      if (percentage > highestPercentage) {
        highestPercentage = percentage;
        highestPercentageTitle = item['sub category'];
      }

      return {
        id: index + 1,
        title: item['sub category'],
        percentage: percentage + '%',
      };
    });



    if (!modifiedData.length) {
      this.loadingCommonIssues = false;
      return;
    }

    this.commonIssues = modifiedData;
    this.highestPercentageTitle = highestPercentageTitle;

    const remainingRank = 100 - highestPercentage;

    this.chartData['datasets'][0].data = [remainingRank, highestPercentage];
    console.log(this.chartData);
    this.chartDataUpdated += 1;
    this.loadingCommonIssues = false;
  }
}
