import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
var plugin = function (chart) {
  var width = chart.chart.width;
  var height = chart.chart.height;
  var ctx = chart.chart.ctx;

  ctx.restore();
  var fontSize = (height / 114).toFixed(2);
  ctx.font = fontSize + 'em sans-serif';
  ctx.fillStyle = '#ffffff';
  ctx.textBaseline = 'middle';

  var text = 'Status';
  var textX = Math.round((width - ctx.measureText(text).width) / 2);
  var textY = height / 2;

  ctx.fillText('', textX, textY);
  ctx.save();

  if (this.neom) {
    const datasets = chart.data.datasets;

    datasets.forEach((dataset, datasetIndex) => {
      if (datasetIndex === 0) {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (!meta.hidden) {
          const arc = meta.data[1];
          const startAngle = arc._model.startAngle;
          const endAngle = arc._model.endAngle;
          const outerRadius = arc._model.outerRadius;

          // Create a gradient shadow effect
          const gradient = ctx.createRadialGradient(
            arc._model.x,
            arc._model.y,
            0,
            arc._model.x,
            arc._model.y,
            outerRadius
          );
          gradient.addColorStop(0, '#9fFFFF'); // Start color (neon glow color)
          gradient.addColorStop(1, 'rgba(159, 255, 255, 0)'); // End color (fully transparent)

          // Apply the gradient shadow effect directly on the chart's border
          ctx.beginPath();
          ctx.arc(arc._model.x, arc._model.y, outerRadius, startAngle, endAngle);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#9fFFFF'; // Remove black border
          ctx.shadowColor = gradient; // Use the gradient as the shadow color
          ctx.shadowBlur = 10; // Adjust the shadow blur to your preference
          ctx.stroke();
          ctx.shadowBlur = 0; // Reset shadow to prevent it from affecting other elements
        }
      }
    });
  }
};

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: {
      required: true,
      type: Object,
      default: () => ({
        responsive: false,
        maintainAspectRatio: false,
      }),
    },
    renderEvent: {
      required: false,
      type: String,
      default: '',
    },
    text: {
      required: false,
      type: String,
      default: 'Status',
    },
    neom: {
      required: false,
      type: Boolean,
      default: false,
    },
    chartDataUpdated: {
      default: 0,
      required: false,
    },
  },
  mounted() {
    // @ts-ignore
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: plugin,
    });
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  },

  beforeDestory() {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    this.$data._chart.destory();
  },

  watch: {
    chartDataUpdated(val) {
      // @ts-ignore
      this.renderChart(this.chartData, this.options);
    },
  },
};
